.map {
  height: 95%;
  border-radius: 0.5em;
  overflow: hidden;
}

.container-home {
  top: 6vh;
  bottom: 0px;
  left: 0;
  right: 0;
}

.footer {
  left: 0;
  z-index: 2;
}

.columns {
  max-height: 100%;
}

.homepage-button {
  background-color: #3bafda;
  border-radius: 1em;
  text-align: center;
  vertical-align: middle;
  color: white;
  font-size: 0.8em;
  margin: 3px;
  height: 2em;
  padding: 0.2em 0.5em 0.2em 0.5em;
}

.homepage-button-icon {
  background-color: #3bafda;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  color: white;
  font-size: 0.8em;
  margin: 3px;
  height: 2em;
  width: 2em;
}
* {
  -ms-overflow-style: none;
}
.dropdown-menu {
  background-color: #454c52;
}
.dropdown-item {
  color: #225c8b;
}
.dropleft {
  text-align: center;
}
.show .dropleft .dropdown-toggle {
  background-color: #454c52;
  color: #9f9f9f;
  border: 0;
  font-weight: bold;
}
.dropdown-item {
  text-align: center;
}

.rdrInputRangeInput {
  background-color: white;
}
::-webkit-scrollbar {
  display: none;
}
.MuiCard-root {
  cursor: pointer;
}

.homeLeftColumn {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homeLeftColumnV2 {
  height: 95%;
}

.homeRightColumn {
  height: 95%;
  overflow: auto;
  background-color: linear-gradient(0deg, rgba(0, 1, 7, 0.89), rgba(0, 1, 7, 0.89));
  border-radius: 0.5em;
  // padding: 0.5em;
}

.homeStatisticPanel {
  width: 100%;
  height: 100%;
  background-color: $container-color;
  flex-basis: 30%;
  border-radius: 0.5em;
}

.homeStatisticPanelV2 {
  width: 100%;
  height: 20%;
  border-radius: 0.5em;
}

.assetImageContainer {
  // background-color: $container-color;
  background: transparent;
  border-radius: 5px;
  padding: 2px;
  height: 30px;
  width: 70px;
}

.assetIconImage {
  width: 65px;
  height: 100%;
  object-fit: contain;
  text-align: center;
}

#detection-filter > .dropdown {
  // .btn-sm {
  //   padding: 0.1em 0;
  //   background-color: #060a2f;
  //   border-color: #060a2f;
  // }
  // .dropdown-menu {
  //   background-color: $background-color;
  //   .dropleft {
  //     .dropdown-toggle {
  //       width: 100%;
  //     }
  //   }
  // }
  .btn-sm {
    padding: 0.1em 0;
    background: transparent;
    border-bottom: 1px solid #0BA0F9;
  }
  .dropdown-menu {
    // background-color: $background-color;
    background: linear-gradient(179.78deg, #142032 74.22%, #1E304C 102.03%), linear-gradient(0deg, #23CFFC, #23CFFC);
    border: 1px solid #0BA0F9;
    .dropleft {
      .dropdown-toggle {
        width: 100%;
      }
    }
  }
}
.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.animated-text {
  font-size: 14px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-out;
}

.animated-text:hover {
  font-size: 16px;
  border-bottom: 2px solid #007BFF;
}

.gradient-overlay {
  pointer-events: none;
  position: absolute;
  // top: 0;
  // right: 0;
  width: 100%; /* Adjust the width to control the gradient size */
  height: 100%;
  top: 0px;
  left: 0px;
  // width: 798px;
  // height: 798px;
  background: radial-gradient(ellipse at top, #225C8B,#225C8B99,  #225C8B66, #225C8B33, transparent, transparent); /* Define your gradient colors */
  // background: radial-gradient(ellipse at top, #FFFFFF66, transparent, transparent); /* Define your gradient colors */
  z-index: 1; /* Ensure it's above the map */
}

.side-panel {
  height: 80vh;
  overflow-y: auto;
  padding: 10px;
}

.side-panel-hide {
  height: -webkit-fill-available;
  overflow-y: auto;
  padding: 10px;
}

.side-panel-hide-content {
  display: none;
}

.side-panel-button-collapse { 
  position: absolute;
  bottom: -12px;
  left: 47%;
  cursor: pointer;
}
.gm-fullscreen-control > img {
  filter: invert(71%) sepia(68%) saturate(6399%) hue-rotate(177deg) brightness(101%) contrast(96%);
  height: 15px !important;
}

.gm-control-active > img {
  filter: invert(71%) sepia(68%) saturate(6399%) hue-rotate(177deg) brightness(101%) contrast(96%);
  height: 15px !important;
}

.gm-control-active {
  background-color: #131D2E !important;
  outline: 1px solid #0BA0F9 !important;
}

.gm-fullscreen-control {
  background-color: #131D2E !important;
  margin-top: 22px !important;
  outline: 1px solid #0BA0F9 !important;
}

.mapfilter-button {
  position: absolute;
  top: 3%;
  right: 23%;
  z-index: 4;
  background: transparent;
  background-color: red;
}

#map-filter > .dropdown {
  // .btn-sm {
  //   padding: 0.1em 0;
  //   background-color: #060a2f;
  //   border-color: #060a2f;
  // }
  // .dropdown-menu {
  //   background-color: $background-color;
  //   .dropleft {
  //     .dropdown-toggle {
  //       width: 100%;
  //     }
  //   }
  // }
  .btn-primary {
    border: none;
  }
  .btn-sm {
    padding: 0.1em 0;
    background: linear-gradient(0deg, rgba(0, 1, 7, 0.49), rgba(0, 1, 7, 0.49)),
  }
  .dropdown-menu {
    // background-color: $background-color;
    background: linear-gradient(179.78deg, #142032 74.22%, #1E304C 102.03%), linear-gradient(0deg, #23CFFC, #23CFFC);
    border: 1px solid #0BA0F9;
    .dropleft {
      .dropdown-toggle {
        width: 100%;
      }
    }
  }
}