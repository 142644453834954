/*
Template Name: Minton - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 4.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

@import "bootstrap";
@import "app";

body {
  background-color: $background-color;
}

.nav-link {
  cursor: pointer;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

div,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea {
  font-size: 100%;
  font-family: Poppins-Regular, Sans-Serif;
}

@font-face {
  font-family: plateia;
  src: url("../fonts/plateia-bold.ttf");
}

@font-face {
  font-family: nulshock;
  src: url("../fonts/nulshock-bd.ttf");
}

.bblr {
  border-bottom-left-radius: 0.5em;
}

.bbrr {
  border-bottom-right-radius: 0.5em;
}

.btlr {
  border-top-left-radius: 0.5em;
}

.btrr {
  border-top-right-radius: 0.5em;
}

.half-transparent {
  opacity: 0.5;
}

.nav-item:last-child > a.nav-link {
  border-top-right-radius: 0.5em !important;
}
.nav-item:first-child > a.nav-link {
  border-top-left-radius: 0.5em !important;
}

.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.color-foreground {
  color: $foreground-color;
}
.color-highlight {
  color: $highlight-color !important;
}

.color-disabled {
  color: $disabled-color;
}

.background-color-foreground {
  background-color: $foreground-color;
}

.background-color-light-foreground {
  background-color: $light-foreground-color;
}

.background-color-highlight {
  background-color: $highlight-color;
}

.title-font {
  font-family: plateia;
}

.column-title {
  @extend .color-highlight;
  @extend .title-font;
  padding-top: 0.7em;
  padding-bottom: 0.2em;
  margin-bottom: 0.6em;
  font-size: 1em;
}

.animatable {
  transition: all 0.5s;
}

.non-active {
  @extend .color-disabled;
  cursor: pointer;

  &:hover {
    @extend .color-highlight;
  }
}

.normal-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.marker-glow-animate {
  border-radius: 50%;
  background-color: #00ff00;
  border: 0.1em solid white;
  box-shadow: 0 0 1.5em #00ff00;
  cursor: pointer;
  animation: glow 1s infinite;
}

.marker-glow {
  border-radius: 50%;
  border: 0.1em solid white;
  cursor: pointer;
}

@keyframes glow {
  from {
    box-shadow: 0 0 0 #00ff00;
  }
  to {
    box-shadow: 0 0 2em #00ff00;
  }
}

.marker-glow-animateV2 {
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 1.5em white;
  cursor: pointer;
  animation: glowV2 1s infinite;
}

.marker-glow {
  border-radius: 50%;
  cursor: pointer;
}

@keyframes glowV2 {
  from {
    box-shadow: 0 0 0 white;
  }
  to {
    box-shadow: 0 0 2em white;
  }
}

.gradient-tagline{
  font-family: "nulshock";
  font-size: 1em;
  background: linear-gradient(#0BFFB0,#009096);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.MuiMenu-paper {
  border: 1px solid $highlight-color;
}

tbody tr:hover {
  background-color: $container-color;
}
