.footer {
  bottom: 0;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  color: $gray-600;
  left: $leftbar-width;
  background-color: "";
  box-shadow: 0 0 1px rgba(50, 58, 70, 0.1);
}

@include media-breakpoint-down(sm) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}
