.liveCameraContainer {
  width: 100%;
  height: 100%;
  flex-basis: 30%;
  border-radius: 0.5em;
  position: relative;
  .fullscreen {
    ._loading_overlay_wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}

.liveCameraContainerV2 {
  width: 100%;
  height: 20%;
  border-radius: 0.5em;
  position: relative;
  .fullscreen {
    ._loading_overlay_wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}

.liveCameraMenuContainer {
  width: 100%;
  height: 100%;
  flex-basis: 30%;
  border-radius: 0.5em;
  overflow-y: auto;
  margin-top: 0.5em;
}

.liveCameraMenuContainerV2 {
  width: 100%;
  height: 7vh;
  border-radius: 0.5em;
  overflow-y: auto;
  margin-block: 0.5em;
}

.liveCameraVideo {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 0.5em;
}

.liveCameraButton {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border: 0px !important;
  border-radius: 50%;
  // background-color: #3bafda !important;
}

.liveCameraText {
  position: absolute;
  top: 0.1em;
  left: 0.5em;
  color: $highlight-color;
  font-size: large;
  font-weight: bold;
  text-shadow: 0 0 8px black;
}

.liveCameraTextTopCenter {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
}

.liveCameraInit {
  position: absolute;
  font-size: 1em;
  font-style: italic;
  width: 100%;
  height: 100%;
  border-radius: 0.5em;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: $container-color;
}

.liveCameraInit-v2 {
  font-size: 1em;
  font-style: italic;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #09121E;
}

.vertical-marker-line {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
  margin-left: -1px;
  border: 1px solid green;
}

.horizontal-marker-line {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 50%;
  margin-bottom: -1px;
  border: 1px solid green;
}