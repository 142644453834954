.modal-dialog {
  max-height: none;
  .modal-content {
    width: auto;
    margin: auto;
    background-color: $light-foreground-color;
    border-radius: 0.5em;
    .modal-header {
      border: 0;
      padding-bottom: 0;
      h4 {
        font-size: larger;
        color: $highlight-color;
        margin-top: 0px;
      }
    }
    .modal-footer {
      border: 0;
    }
  }
}
.modal-dialog-two {
  max-width: 60%;
  left: 20%;
  top: 20%;
  .modal-dialog {
    .modal-content {
      background-color: $light-foreground-color;
      border-radius: 0.5em;
      padding-left: 1.5em;
      padding-bottom: 1em;
      .modal-header {
        border-bottom: 0;
        padding-bottom: 0;
        // margin-left: 1.5em;
        h4 {
          font-size: larger;
          color: $highlight-color;
          margin-top: 0px;
        }
      }
    }
  }
}

.date input {
  width: 500;
  height: 500;
}

.fb-90 {
  flex-basis: 90%;
  height: 3em;
  .css-2b097c-container {
    width: 100%;
  }
  .react-datepicker-wrapper {
    width: 100%;
    input {
      width: 100%;
      height: 2.5em;
    }
  }
}

.fb-50 {
  flex-basis: 50%;
}

.close {
  color: $highlight-color;
  font-size: 2em;
}
