.annotation-container {
  width: 100%;
  height: 100%;
  // background-color: $light-foreground-color;
  border-radius: 0.5em;
  padding: 1em;
}

.annotation-body {
  position: relative;
  width: 100%;
  height: 100%;
  // background-color: $light-foreground-color;
  border-radius: 0.5em;
}

.annotation-body-v2 {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #092437;
  border-radius: 0.5em;
}

.cover-fullscreen {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.modal-backdrop {
  opacity: 0.9 !important;
  background-color: #24292e;
}

.annotation-top-menu {
  background-color: #00000088;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 0.1em;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.annotation-bottom-menu {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1em;
  font-size: 2em;
}

.left-menu {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  float: left;
}

.right-menu {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  float: right;
}

.annotation-button {
  /* background-color: #3bafda; */
  border-radius: 50%;
  color: white;
  font-size: 0.8em;
  margin: 0.2em;
  height: 2em;
  width: 2em;
  line-height: 0.5rem;
  border: 0;
}

.dialog-button {
  position: absolute;
  left: 95%;
}

.slider-label {
  padding: 0.5em;
  font-size: 1.5em;
  &-true {
    @extend .slider-label;
    color: #00e0c7;
  }
  &-false {
    @extend .slider-label;
    color: #ff0000;
  }
}

.info-window {
  position: absolute;
  top: 7%;
  left: 1%;
  background-color: #242551aa;
  color: white;
  z-index: 1;
}

.info-window-v2 {
  position: absolute;
  top: 7%;
  left: 8%;
  background-color: #04243266;
  color: white;
  z-index: 1;
}

.info-window-title {
  background-color: #141e48aa;
  color: #3bafda;
  padding: 0.5em;
  box-shadow: 0 1px 1px black;
  align-items: center;
}

.info-window-title-v2 {
  background-color: #133549;
  color: #ACD4FB;
  padding: 0.75em;
  box-shadow: 0 1px 1px black;
  align-items: center;
}

.info-label {
  font-size: 0.7em;
  color: #adb5bd;
}

.info-data {
  font-size: 0.8em;
  color: white;
}

.info-image {
  object-fit: cover;
  cursor: zoom-in;
  width:30px;
  height:60px;
}

.dialog-image {
  width: 500px;
}

.no-data {
  font-size: 15px;
  font-weight: 600;
  color: #adb5bd;
  text-align: center;
  justify-content: center;
  padding-top: 1rem;
}
