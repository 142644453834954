//
// right-sidebar.scss
//

.right-bar {
  background-color: $container-color;
  //   box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 20px 0 black;
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: $rightbar-width;
  height: 70%;
  z-index: 9999;
  float: right !important;
  right: -($rightbar-width + 10px);
  top: 0;
  bottom: 0;

  .rightbar-title {
    background-color: $foreground-color;
    padding: 25.5px 25px;
    color: $highlight-color;
    a {
      background-color: $highlight-color;
    }
    h4 {
      color: $highlight-color;
    }
  }
  .right-bar-toggle {
    background-color: lighten($dark, 7%);
    height: 24px;
    width: 24px;
    line-height: 24px;
    color: $white;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px;

    &:hover {
      background-color: lighten($dark, 10%);
    }
  }
  .user-box {
    padding: 25px;
    text-align: center;
    background-color: $container-color;
    .user-img {
      position: relative;
      height: 64px;
      width: 64px;
      margin: 0 auto 15px auto;
      .user-edit {
        position: absolute;
        right: -5px;
        bottom: 0px;
        height: 24px;
        width: 24px;
        background-color: $container-color;
        line-height: 24px;
        border-radius: 50%;
        box-shadow: $box-shadow-lg;
      }
    }
    h5 {
      margin-bottom: 2px;
      a {
        color: $dark;
      }
    }
  }
}

.right-bar-v2 {
  background-color: $container-color;
  //   box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 20px 0 black;
  position: fixed;
  display: none;
  transition: all 200ms ease-out;
  width: $rightbar-width;
  height: 365px;
  z-index: 9999;
  right: 30px;
  top: 7%;
  bottom: 0;
  .rightbar-title {
    background-color:  #021935;
    padding: 15px 15px;
    color: $highlight-color;
    a {
      background-color: $highlight-color;
    }
    h4 {
      color: $highlight-color;
    }
  }
  .right-bar-toggle {
    background-color: #021935;
    height: 24px;
    width: 24px;
    line-height: 24px;
    color: $white;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px;

    &:hover {
      background-color: lighten($dark, 10%);
    }
  }
  .user-box-v2 {
    background-color: #021935;
    .user-img {
      position: relative;
      margin-right: 20px;
      .user-edit {
        position: absolute;
        right: -5px;
        bottom: 0px;
        height: 24px;
        width: 24px;
        background-color: $container-color;
        line-height: 24px;
        border-radius: 50%;
        box-shadow: $box-shadow-lg;
      }
    }
  }
}
.activity-bar {
  background: linear-gradient(180deg, #022146 0%, #020C18 100%);
  box-shadow: 0 0 20px 0 black;
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: $rightbar-width;
  z-index: 9999;
  float: right !important;
  right: -($rightbar-width + 10px);
  top: 0;
  bottom: 0;

  .activitybar-title {
    background-color: $foreground-color;
    padding: 25.5px 25px;
    a {
      background-color: $highlight-color;
    }
    h4 {
      color: $highlight-color;
    }
  }
  .activity-bar-toggle {
    background-color: lighten($dark, 7%);
    height: 24px;
    width: 24px;
    line-height: 24px;
    color: $white;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px;

    &:hover {
      background-color: lighten($dark, 10%);
    }
  }
  .user-box {
    padding: 25px;
    text-align: center;
    .user-img {
      position: relative;
      height: 64px;
      width: 64px;
      margin: 0 auto 15px auto;
      .user-edit {
        position: absolute;
        right: -5px;
        bottom: 0px;
        height: 24px;
        width: 24px;
        background-color: $white;
        line-height: 24px;
        border-radius: 50%;
        box-shadow: $box-shadow-lg;
      }
    }
    h5 {
      margin-bottom: 2px;
      a {
        color: $dark;
      }
    }
  }
}

// Rightbar overlay
.rightbar-overlay {
  background-color: rgba($dark, 0.55);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}
.rightbar-overlay-v2 {
  background-color: rgba($dark, 0.55);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}
.activitybar-overlay {
  background-color: rgba($dark, 0.55);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}

.right-bar-enabled {
  .right-bar {
    right: 0;
  }
  .right-bar-v2 {
    right: 20px;
    top: 7%;
    display: block;
  }
  .rightbar-overlay {
    display: block;
  }
  .rightbar-overlay-v2 {
    display: block;
  }
}
.activity-bar-enabled {
  .activity-bar {
    right: 0;
  }
  .activitybar-overlay {
    display: block;
  }
}

@include media-breakpoint-down(sm) {
  .right-bar {
    overflow: auto;
    .slimscroll-menu {
      height: auto !important;
    }
  }
  .right-bar-v2 {
    overflow: auto;
    .slimscroll-menu {
      height: auto !important;
    }
  }
}
.activity-bar {
  overflow: auto;
  .slimscroll-menu {
    height: auto !important;
  }
}

.main-body {
  .list-group {
    .list-group-item {
      background-color: $container-color;
      //   border: 1px solid $disabled-color;
      padding: 0px;
    }
  }
}

.main-body-v2 {
  .list-group {
    .list-group-item {
      background: transparent;
      //   border: 1px solid $disabled-color;
      padding: 0px;
    }
  }
}
