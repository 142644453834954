.menuHeader :hover {
  cursor: pointer;
}

.liveMenuCard {
  margin-bottom: 0.5rem;
  border: 0;
  background-color: $container-color;
  border-radius: 0.5em;
}

.liveMenuCardHeader {
  background-color: #3bafda;
  color: #383f45;
  border-radius: 0.5em !important;
  border-bottom: 0;
  text-align: center;
  font-weight: bold;
  transition: all 0.5s;
  padding: 0.5rem 0.8rem;
}

.expanded {
  border-radius: 0.5em 0.5em 0 0 !important;
}

.liveMenuCardBody {
  background-color: $container-color;
  color: #3bafda;
  border-radius: 0.5em;
  text-align: left;
  padding-bottom: 0px;
}

.liveMenusCameraRow{
  cursor: pointer;
}

.liveMenusCameraRow:hover{
  background-color: #00000055;
}