.report-table {
  thead {
    background-color: #1d2151;
    color: #3bafda;
    border-bottom: 0px;
    th {
      border: 0px;
    }
  }
  tbody {
    border-top: 0px;
  }
  tbody + tbody {
    border-top: 0px;
  }
  td {
    border-top: 1px solid $disabled-color;
  }
}

.analytic-table {
  thead {
    background-image: linear-gradient(to right, #020A25, #0BA0F9, #020A25);
    color: #ffffff;
    border: 1px solid #225c8b;
    th {
      border: 0px;
    }
  }
  tbody {
    border-top: 0px;
    background-image: radial-gradient(circle at 50% -130%, #225C8B, #020A25);
    border: 1px solid #225c8b;
  }
  tbody + tbody {
    border-top: 0px;
  }
  td {
    border-top: 1px solid $disabled-color;
    vertical-align: middle;
  }
  tbody tr:hover {
    background-color: transparent;
  }
}